<template>
  <div>
    <div class="py-40 md:py-52 lg:py-60">
      <div class="bg-blue-2 md:w-2/3 lg:w-1/2 p-5 md:p-7">
        <p
          class="text-2xl md:text-3xl lg:text-4xl text-white xl:text-5xl font-bold"
        >
          Looking For A Expert
          <span class="text-blue-1">Waterproofing</span> Contractor?
        </p>
        <div class="bg-blue-1 h-0.5 my-3"></div>
        <p class="text-white md:text-xl leading-tight text-lg font-medium">
          We Provide All Waterproofing Needs!
        </p>

        <div v-if="!hideEnquiry" class="mt-5 md:mt-7">
          <p
            class="bg-red-600 transform transition duration-300 hover:scale-105 text-center rounded-md py-1 w-44 text-white"
          >
            <a href="https://wa.me/60163253316">Enquire Us Now</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hideEnquiry: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
