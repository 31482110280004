<template>
  <div>
    <!-- header -->
    <div
      class="bg-cover bg-center bg-no-repeat"
      style="background-image: url('/images/bg-banner.jpg')"
    >
      <div class="container">
        <TopHeader />
        <div class="bg-white h-20"></div>
        <!-- banner -->
        <MainBanner />
      </div>
    </div>

    <!-- why choose us -->
    <div class="py-10">
      <div class="container">
        <div class="text-center text-blue-2">
          <p class="text-2xl font-semibold">Why Tat Waterproofing?</p>
          <div class="bg-blue-1 w-10 h-1 my-3 mx-auto"></div>
        </div>

        <div class="mt-8 lg:max-w-4xl lg:mx-auto">
          <div class="md:flex md:flex-wrap">
            <div
              v-for="(item, i) in choose_us"
              :key="i"
              class="mx-auto md:w-1/2 pb-4 md:px-2"
            >
              <div
                class="inline-flex bg-white w-full rounded-md transition duration-200 hover:bg-blue-1 shadow-md p-5 items-center"
              >
                <img
                  :src="'/images/' + item.image + '.png'"
                  :alt="item.alt"
                  class="w-14"
                />
                <div class="pl-2">
                  <p class="leading-tight text-lg font-medium text-gray-700">
                    {{ item.alt }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- services -->
    <div class="py-10 bg-gray-50">
      <div class="container">
        <div class="text-center text-blue-2">
          <p class="text-2xl font-semibold">Area We Help</p>
          <div class="bg-blue-1 w-10 h-1 my-3 mx-auto"></div>
          <p class="pt-3 md:text-base text-gray-600 text-sm leading-tight">
            Wide range of waterproofing methods and applications to tackle all
            leaking issues and waterproofing required. Waterproofing solutions
            for residential and commercial
          </p>
        </div>

        <div class="mt-6 md:flex lg:justify-center md:flex-wrap">
          <div
            v-for="(item, i) in services"
            :key="i"
            class="pb-5 md:w-1/2 lg:w-1/4 md:px-2"
          >
            <div class="text-center pb-4">
              <p class="text-xl font-semibold">{{ item.alt }}</p>
            </div>
            <div class="overflow-hidden">
              <img
                :src="'/images/' + item.image + '.jpg'"
                :alt="item.alt"
                class="transform transition duration-200 ease-linear hover:scale-110"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- methods -->
    <div class="py-10 bg-blue-2">
      <div class="container">
        <div class="text-center text-white">
          <p class="text-2xl font-semibold">Our Waterproofing Methods</p>
          <div class="bg-blue-1 w-10 h-1 my-3 mx-auto"></div>
        </div>

        <div class="mt-6 md:flex lg:justify-center md:flex-wrap">
          <div
            v-for="(item, i) in methods"
            :key="i"
            class="pb-5 md:w-1/2 lg:w-1/3 md:px-2"
          >
            <div class="text-center text-white pb-4">
              <p class="text-xl font-semibold">{{ item.alt }}</p>
            </div>
            <div class="overflow-hidden">
              <img
                :src="'/images/' + item.image + '.jpg'"
                :alt="item.alt"
                class="transform transition duration-200 ease-linear hover:scale-110"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- other services -->
    <div class="py-10">
      <div class="container">
        <div class="text-center text-blue-2">
          <p class="text-2xl font-semibold">Other Services</p>
          <div class="bg-blue-1 w-10 h-1 my-3 mx-auto"></div>
        </div>

        <div class="mt-6 md:flex lg:justify-center md:flex-wrap">
          <div
            v-for="(item, i) in others"
            :key="i"
            class="pb-5 md:w-1/2 lg:w-1/3 md:px-2"
          >
            <div class="text-center pb-4">
              <p class="text-xl font-semibold">{{ item.alt }}</p>
            </div>
            <div class="overflow-hidden">
              <img
                :src="'/images/' + item.image + '.jpg'"
                :alt="item.alt"
                class="transform transition duration-200 ease-linear hover:scale-110"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- footer -->
    <div id="element" class="py-10 bg-blue-1">
      <div class="container lg:flex lg:items-center">
        <div class="md:w-2/3 md:mx-auto lg:w-1/2 lg:order-2">
          <!-- enquiry form  -->
          <div class="p-6 rounded-md bg-yellow-1 bg-opacity-60">
            <div class="text-center">
              <p class="text-3xl font-semibold text-blue-2">Send an Enquiry</p>
              <div class="bg-blue-2 w-14 h-0.5 mx-auto my-3"></div>
              <p class="pt-3 text-sm text-gray-600">
                Fill out the form below and we'll get back to you as soon as
                possible
              </p>
            </div>
            <EnquiryForm class="pt-4" />
          </div>
        </div>
        <div class="pt-5 lg:w-1/2 lg:pr-4">
          <img
            src="/images/main-logo.png"
            alt=""
            class="mx-auto w-60 md:w-52"
          />
          <div class="pt-3 text-center text-blue-2">
            <div class="pb-5">
              <h1 class="pb-2 text-lg font-medium lg:text-xl text-blue-1">
                Tat Waterproofing Enterprise
              </h1>
              <h1 class="text-lg font-normal leading-tight md:text-xl">
                No.2A, jalan USJ 1/3K, Grand Ville USJ 1, Subang Jaya, 47600
                Selangor
              </h1>
              <div class="pt-5">
                <p class="font-bold">Message Us on WhatsApp</p>
                <p
                  class="font-medium transition duration-300 transform hover:scale-110 hover:text-purple-1"
                >
                  <a href="https://wa.me/60163253316" class="">016-325 3316</a>
                </p>
              </div>

              <div class="pt-4 font-medium">
                <p class="font-bold">Call Us</p>
                <p
                  class="font-medium transition duration-300 transform hover:scale-110 hover:text-purple-1"
                >
                  <a href="tel:+60163253316 ">016-325 3316 </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="py-3 bg-blue-2">
      <div class="container mx-auto">
        <h1 class="text-xs text-center text-white lg:text-sm">
          Website maintained by Activa Media. All Rights Reserved.
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import TopHeader from "@/components/TopHeader.vue";
import EnquiryForm from "@/components/EnquiryForm.vue";
import MainBanner from "@/components/MainBanner.vue";

export default {
  name: "Home",
  components: {
    TopHeader,
    EnquiryForm,
    MainBanner,
  },
  data() {
    return {
      choose_us: [
        {
          image: "choose-1",
          alt: "Over 2 Decades Of Experience",
        },
        {
          image: "choose-2",
          alt: "Reasonable Pricing, No Compromise on Quality",
        },
        {
          image: "choose-3",
          alt: "Trained Specialists",
        },
        {
          image: "choose-4",
          alt: "Quality Material and Right Equipment",
        },
      ],
      services: [
        {
          image: "service-1",
          alt: "Ceiling",
        },
        {
          image: "service-2",
          alt: "Window",
        },
        {
          image: "service-3",
          alt: "Floor",
        },
        {
          image: "service-4",
          alt: "Roof",
        },
        {
          image: "service-5",
          alt: "Balcony",
        },
        {
          image: "service-6",
          alt: "Gutter",
        },
        {
          image: "service-7",
          alt: "External Facade",
        },
      ],
      methods: [
        {
          image: "method-1",
          alt: "Abceiling",
        },
        {
          image: "method-2",
          alt: "Cementitious",
        },
        {
          image: "method-3",
          alt: " Epoxy Grout",
        },
        {
          image: "method-4",
          alt: "Pu Injection",
        },
        {
          image: "method-5",
          alt: "Pu Proof",
        },
      ],
      others: [
        {
          image: "other-1",
          alt: "Epoxy Flooring",
        },
        {
          image: "other-2",
          alt: "Metal Deck",
        },
      ],
    };
  },
};
</script>
