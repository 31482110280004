<template>
  <form @submit.prevent="submit" class="mx-auto space-y-4 text-left">
    <div>
      <div class="bg-white rounded flex flex-col py-1.5 px-2">
        <label for="name" class="text-sm text-gray-400"
          >Name <span class="text-red-400">(Required)</span></label
        >
        <input
          v-model="fields.name"
          id="name"
          type="text"
          class="text-gray-600 bg-transparent focus:outline-none"
        />
      </div>
      <div v-if="errors && errors.name" class="text-red-900">
        {{ errors.name[0] }}
      </div>
    </div>
    <div>
      <div class="bg-white rounded flex flex-col py-1.5 px-2">
        <label for="email" class="text-sm text-gray-400"
          >E-Mail <span class="text-red-400">(Required)</span></label
        >
        <input
          v-model="fields.email"
          id="email"
          type="email"
          class="text-gray-600 bg-transparent focus:outline-none"
        />
      </div>
      <div v-if="errors && errors.email" class="text-red-900">
        {{ errors.email[0] }}
      </div>
    </div>
    <div>
      <div class="bg-white rounded flex flex-col py-1.5 px-2">
        <label for="contact_number" class="text-sm text-gray-400"
          >Contact Number <span class="text-red-400">(Required)</span></label
        >
        <input
          v-model="fields.contact"
          id="contact_number"
          type="tel"
          class="text-gray-600 bg-transparent focus:outline-none"
        />
      </div>
      <div v-if="errors && errors.contact" class="text-red-900">
        {{ errors.contact[0] }}
      </div>
    </div>
    <div>
      <div class="bg-white rounded flex flex-col py-1.5 px-2">
        <label for="message" class="text-sm text-gray-400">Message</label>
        <textarea
          v-model="fields.message"
          id="message"
          rows="4"
          type="text"
          class="h-auto p-0 px-0 m-0 font-sans text-base text-gray-600 bg-transparent border-0 focus:outline-none"
        ></textarea>
      </div>
      <!-- <div v-if="errors && errors.name" class="text-red-900">{{ errors.name[0] }}</div> -->
    </div>
    <div>
      <div class="">
        <div recaptcha-el></div>
        <div class="text-red-900">{{ recaptchaError }}</div>
      </div>
      <br />
      <button
        v-text="busy ? 'Submitting...' : 'Submit'"
        type="submit"
        class="w-full py-2 text-lg font-medium text-white transition border-none rounded bg-blue-2 opacity-90 hover:opacity-100 disabled:cursor-wait disabled:opacity-50"
      ></button>
    </div>
  </form>
</template>

<script>
import hapiMixins from "hapiform-with-recaptcha";

export default {
  mixins: [hapiMixins],
  // components: { InputError },
  data() {
    return {
      endpoint: "https://hapiform.sg/api/55798e56-0f72-4f14-a556-4dd5a29b6286",
      redirectTo: "/thank-you",
      recaptchaDisabled: false,
    };
  },
};
</script>
