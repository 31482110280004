<template>
  <div>
    <!-- header  -->
    <div
      class="fixed top-0 left-0 w-full py-4 transition duration-200 ease-linear bg-transparent"
      :class="fixtop ? 'bg-white shadow-md z-10' : 'bg-white'"
    >
      <div class="container">
        <div class="flex items-center justify-between">
          <img src="/images/main-logo.png" alt="" class="w-20 md:w-24" />
          <div class="flex gap-2 md:hidden">
            <div>
              <a href="https://wa.me/60163253316">
                <img src="/images/whatsapp.png" alt="" class="w-10" />
              </a>
            </div>
            <div>
              <a href="tel+60163253316">
                <img src="/images/call.png" alt="" class="w-10" />
              </a>
            </div>
          </div>
          <div class="hidden text-sm lg:text-base md:flex md:gap-2">
            <div class="w-44 md:w-56">
              <a href="https://wa.me/60163253316">
                <p
                  class="py-2 font-semibold text-center text-white bg-green-600 rounded-full"
                >
                  Whatsapp Us Today
                </p>
              </a>
            </div>
            <div class="w-44 md:w-56">
              <a href="tel:+60163253316">
                <p
                  class="py-2 font-semibold text-center text-white bg-red-600 rounded-full"
                >
                  Call Us For Free Quote
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fixtop: false,
    };
  },
  methods: {
    scrollListener: function (e) {
      this.fixtop = window.scrollY > 110;
    },
  },
  mounted: function () {
    window.addEventListener("scroll", this.scrollListener);
  },
  beforeDestroy: function () {
    window.removeEventListener("scroll", this.scrollListener);
  },
};
</script>

<style></style>
